import { UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { getChatScriptSource } from '../commons/assistente-pessoal/assistente-pessoal.config';
import { getAvaliacoesConfig } from '../commons/avaliacoes/avaliacoes.config';
import { getBlocklistProgressoSemestreConfig } from '../commons/blocklist-dashboard-semestre/blocklist-dashboard-semestre.config';
import { detectaNavegador } from '../commons/detecta-navegador/detecta-navegador.config';
import { getElegivelProgressoSemestre } from '../commons/elegibilidade-progresso-semestre/elegibilidade-progresso-semestre.config';
import { getFormacaoSociomeocionalConfig } from '../commons/formacao-socioemocional/formacao-socioemocional.config';
import { getChatHelp } from '../commons/menu/chat-help/chat-help.config';
import { getSubMenusDisciplinas } from '../commons/menu/disciplinas/disciplinas.config';
import { getItensMenuConfig } from '../commons/menu/menu.config';
import { paraMsalConfiguration } from '../commons/msal.commons';
import { getTapumeAvaliacoesConfig } from '../commons/tapume-avaliacoes/tapume-avaliacoes.config';
import { getTapumeConfig } from '../commons/tapume-erro-carregamento/tapume-erro-carregamento.config';
import { Environment } from '../types';
import { AJUDA_LOGIN, EMPREGABILIDADE, EXTENSOES_VALIDAS_ENVIO_ARQUIVO, tapume } from './common';

const buildSettings = (): UserManagerSettings => ({
  authority: 'https://login.microsoftonline.com/da49a844-e2e3-40af-86a6-c3819d704f49/v2.0',
  client_id: '73bc1787-af8e-440c-a50a-ceb29030a63c',
  redirect_uri: 'https://estudante.ibmec.br',
  post_logout_redirect_uri: 'https://estudante.ibmec.br',
  monitorSession: false,
  userStore: new WebStorageStateStore({ store: localStorage }),
  prompt: 'select_account',
  response_type: 'code',
  automaticSilentRenew: true,
  loadUserInfo: false,
  scope: '73bc1787-af8e-440c-a50a-ceb29030a63c/.default',
});

const prod = (): Environment => {
  const settings: UserManagerSettings = buildSettings();
  const apiUrl = localStorage.getItem('apiUrl');
  const apiV2Url = localStorage.getItem('apiV2Url');
  const urlQc = localStorage.getItem('urlQc');
  const ambiente = 'prd';
  const marca = 'ibmec';

  return {
    contextos: [{ host: 'https://estudante-sava-ibmec.estacioprd.net' }, { host: 'https://estudante.ibmec.br' }],
    ambiente,
    marca,
    tapume,
    idClienteEnsineme: 'A39EAE23-8E99-27B8-82FC-D5ABAF8953CD',
    API_DEDICADA: apiUrl ?? 'https://apis.estudante.ibmec.br/rest',
    API: apiUrl ?? 'https://sava.ibmec.br/apis',
    API_V2: apiV2Url ?? 'https://aura-api.ibmec.br/aura-frontend',
    URL_QC: urlQc ?? 'https://lms.qcx.com.br',
    URL_REDIRECT_ENSINEME: 'https://stecine.azureedge.net/repositorio/redirect/_redirect.html',
    URL_REDIRECT_REDEFINIR_SENHA: 'https://account.activedirectory.windowsazure.com/ChangePassword.aspx',
    URL_ESQUECEU_SENHA: 'https://passwordreset.microsoftonline.com/',
    URL_EDUCADOR: 'https://educador.ibmec.br',
    URL_FAQ: '/aura-repo/faq-estudante.pdf',
    URL_MANUAL: '/aura-repo/manual-estudante.pdf',
    URL_BASE_CALENDARIO_ACADEMICO: '/aura-repo/calendario-academico/',
    CHAT_HELP: getChatHelp('ibmec'),
    EVENT_LOGIN_ENDPOINT: '/v2/eventos-login/alunos',
    EXTENSOES_VALIDAS_ENVIO_ARQUIVO,
    userManagerSettings: settings,
    msalConfiguration: paraMsalConfiguration({ settings }),
    GTM_ID: 'GTM-56HT2PR',
    TAMANHO_PAGINA_RESPOSTA_TRABALHOS: 1,
    EMPREGABILIDADE,
    AJUDA_LOGIN,
    ITENS_MENU: getItensMenuConfig({ ambiente, marca }),
    ITENS_AVALIACOES: getAvaliacoesConfig({ ambiente, marca }),
    ID_INSTITUICAO_LINKEDIN: '422246',
    URL_SCRIPT_CHAT_ASSISTENTE_PESSOAL: getChatScriptSource(ambiente),
    URL_ACESSO_SIRIUS_EXERCICIOS: 'https://ibmec.saladeavaliacoes.com.br',
    URL_ACESSO_SIRIUS_SIMULADOS: 'https://ibmec.saladeavaliacoes.com.br',
    URL_FICHA_CATALOGRAFICA: `https://ltechnology.com.br/gleison/`,
    SUBMENUS_DISCIPLINAS: getSubMenusDisciplinas(),
    FORMACAO_SOCIOEMOCIONAL: getFormacaoSociomeocionalConfig(),
    TAPUME_AVALIACOES: getTapumeAvaliacoesConfig(),
    TAPUME_CONFIG: getTapumeConfig({ marca }),
    NAVEGADOR_CONFIG: detectaNavegador(),
    BLOCKLIST_PROGRESSO_SEMESTRE: getBlocklistProgressoSemestreConfig(),
    ELEGIBILIDADE_PROGRESSO_SEMESTRE: getElegivelProgressoSemestre(),
  };
};

export default prod;
