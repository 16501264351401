/* eslint-disable react-hooks/exhaustive-deps */
import { Assert } from '@aura/core-lib';
import { ThemeManagerProvider } from '@aura/temas-frontend/dist/contexts/theme-manager/theme-manager.provider';
import { init as initApm } from '@elastic/apm-rum';
import React, { Suspense, lazy, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom/client';
import { SWRConfig } from 'swr';
import { ToastManager } from './components/toast-manager/toast-manager.component';
import { getConfig } from './infra';

const LoadingDefault = lazy(() => import('@aura/components-frontend').then((m) => ({ default: m.LoadingDefault })));
const Tapume = lazy(() => import('./components/tapume/tapume.component').then((m) => ({ default: m.Tapume })));
const Bootstrap = lazy(() => import('./pages/bootstrap/Bootstrap.page'));

const Index: React.FC = () => {
  const environment = useMemo(getConfig, [getConfig]);

  const onLoading = <LoadingDefault />;
  const onError = <Tapume model="DEFAULT" />;

  useEffect(() => {
    const readEnvironment = () => {
      switch (environment.ambiente.toLocaleLowerCase()) {
        case 'dev':
        case 'develop':
        case 'development':
          return 'development';
        case 'qld':
        case 'qa':
        case 'hml':
        case 'homolog':
          return 'homolog';
        case 'prd':
        case 'prod':
        case 'production':
          return 'production';
        case 'ci':
        case 'local':
        default:
          return null;
      }
    };
    const env = readEnvironment();
    if (env) {
      initApm({
        serviceName: `sava-webapp-estudantes`,
        serverUrl: 'https://0a45548b388140afad378bed5b3c4c21.apm.eastus.azure.elastic-cloud.com:443',
        serviceVersion: environment.marca,
        environment: env,
        distributedTracing: true,
        distributedTracingOrigins: [
          new URL(environment.API).origin,
          new URL(environment.API_V2).origin,
          new URL(environment.API_DEDICADA).origin,
          'https://assistente-rest-dev.yduqs.com.br',
          'https://assistente-rest.yduqs.com.br',
          `https://apis.${environment.marca}.trabalhabilidade.prd.yduqs.com.br`,
          `https://apis.${environment.marca}.trabalhabilidade.qld.yduqs.com.br`,
          `https://apis.${environment.marca}.trabalhabilidade.dev.yduqs.com.br`,
        ],
      });
    }
  }, []);

  return (
    <ThemeManagerProvider brand={environment.marca}>
      <ToastManager />
      <Suspense fallback={onLoading}>
        <SWRConfig
          value={{
            provider: () => new Map(),
          }}
        >
          <Bootstrap environment={environment} onLoading={onLoading} onError={onError} />
        </SWRConfig>
      </Suspense>
    </ThemeManagerProvider>
  );
};

const container = document.getElementById('root');

Assert.nonNullable(container);

const root = ReactDOM.createRoot(container);

root.render(<Index />);
