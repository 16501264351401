import {
  BlocklistProgressoSemestreConfig,
  GetBlocklistProgressoSemestreConfig,
} from './blocklist-dashboard-semestre.config.types';

const BLOCKLIST_DASHBOARD_PROGRESS0_SEMESTRE: BlocklistProgressoSemestreConfig = {
  cursos: [],
  modalidades: [],
  periodos: [],
  tipoCursos: [],
};

export const getBlocklistProgressoSemestreConfig: GetBlocklistProgressoSemestreConfig = () => {
  return BLOCKLIST_DASHBOARD_PROGRESS0_SEMESTRE;
};
